.swiper-button-disabled {
  transition: all 0.3s ease;

  opacity: 0.15;
}

.swiper:not(.swiper-initialized) {
  @apply opacity-0;
}

.swiper-pagination-bullet {
  --swiper-pagination-bullet-inactive-color: #c3d9f2;
  --swiper-pagination-color: #5674b4;
  --swiper-pagination-bullet-inactive-opacity: 1;
}
